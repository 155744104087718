<template>
  <div class="w-full">
    <vs-row vs-w="12" vs-type="flex" vs-align="center" vs-justify="flex-end">
      <vs-col vs-w="2" vs-type="flex" vs-align="center" vs-justify="center">
        <ModalCadastroPlanoConta
          @update="getPlanoContas"
        ></ModalCadastroPlanoConta>
      </vs-col>

      <vs-col vs-w="2" vs-type="flex" vs-align="center" vs-justify="center">
        <vs-button
          icon="update"
          color="warning"
          type="relief"
          size="small"
          @click="setChildsPlanoConta()"
          ><b style="font-size: 13px">Atualizar P.C.</b></vs-button
        >
      </vs-col>
    </vs-row>
    <VuePerfectScrollbar class="scroll-plano-conta" :settings="settings">
      <vs-row vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
        <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
          <div class="w-full">
            <Plano
              :nome="'PLANO CONTA'"
              :planoContas="planoContas"
              :depth="0"
            ></Plano>
          </div>
        </vs-col>
      </vs-row>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import ModalCadastroPlanoConta from "./cadastro.vue"
import ModalEditarPlanoConta from "./editar.vue"
import Plano from './plano.vue'
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      planoContas: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000
      },
    }
  },
  methods: {
    async getPlanoContas () {
      this.$vs.loading()
      try {
        this.planoContas = await this.$http.get(`getPlanoContas`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    },
    async setChildsPlanoConta () {
      this.$vs.loading()
      try {
        await this.$http.get(`setChildsPlanoContas`)
        this.$vs.loading.close()

        await this.getPlanoContas()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  components: {
    ModalCadastroPlanoConta,
    ModalEditarPlanoConta,
    Plano,
    ...components
  },
  async mounted () {
    await this.getPlanoContas()
  }
};
</script>

<style>
.actions {
  width: 60px !important;
}
.scroll-plano-conta {
  height: 48em;
}
</style>
