import { render, staticRenderFns } from "./cadastro.vue?vue&type=template&id=36c1b566&"
import script from "./cadastro.vue?vue&type=script&lang=js&"
export * from "./cadastro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports