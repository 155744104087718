<template>
  <div class="w-full">
    <SuccessButton
      class="mr-6"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="
        errors.clear();
        modalCadastroPlanoConta = true;
      "
    ></SuccessButton>
    <SideBar
      title="Cadastrar plano de conta"
      @hide="modalCadastroPlanoConta = false"
      :active="modalCadastroPlanoConta"
      size="small"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12" vs-type="flex">
            <div class="w-full">
              <vs-row vs-w="12" vs-type="flex">
                <vs-col vs-w="12" vs-type="flex">
                  <vs-input
                    class="inputx w-full mx-1 mt-2"
                    name="nomePlanoConta"
                    v-validate="'required'"
                    label="Nome"
                    v-model="planoConta.nome"
                  />
                </vs-col>
                <vs-col vs-w="12" vs-type="flex">
                  <span
                    class="text-danger text-sm mx-2"
                    v-show="errors.has('nomePlanoConta')"
                    >{{ $validators.empty }}</span
                  >
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mb-2" vs-type="flex" vs-w="12">
          <vs-col vs-w="12" vs-type="flex">
            <div class="w-full">
              <vs-row vs-w="12" vs-type="flex">
                <vs-col vs-w="12" vs-type="flex">
                  <div class="w-full mt-2">
                    <label style="color: black; font-size: 0.85rem" class="pl-2"
                      >Plano Pai</label
                    >
                    <el-select
                      filterable
                      clearable
                      remote
                      :remote-method="filtroPlanoConta"
                      name="planoConta"
                      :loading="loading"
                      v-validate="'required'"
                      placeholder="Pesquise"
                      loading-text="Buscando..."
                      class="w-full mx-1"
                      v-model="planoConta.id_parent"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="data in plano_conta"
                        :value="data.id"
                        :label="data.nome"
                        :key="data.id"
                      ></el-option>
                    </el-select>
                  </div>
                </vs-col>
                <vs-col vs-w="12" vs-type="flex">
                  <span
                    class="text-danger text-sm mx-2"
                    v-show="errors.has('planoConta')"
                    >{{ $validators.empty }}</span
                  >
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  name: "ModalCadastroPlanoConta",
  data () {
    return {
      planoConta: {},
      modalCadastroPlanoConta: false,
      error: false,
      plano_conta: {},
      loading: false
    }
  },
  methods: {
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrarPlanoConta()
        } else {
          this.$vs.notify({
            title: "Preencha todos os campos",
            text: "Os campos não devem ser vazios!",
            color: "danger"
          })
        }
      })
    },
    async filtroPlanoConta (query) {
      this.loading = true
      if (query.length > 2) {
        try {
          await this.getPlanoContaByQuery(query)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        }
      }
      this.loading = false
    },
    async getPlanoContaByQuery (query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async cadastrarPlanoConta () {
      this.$vs.loading()
      try {
        const res = await this.$http.post("plano_conta", this.planoConta)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'cadastrar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Cadastro do plano de conta N°' + res.id
        }
        await this.$logger(logData)
        this.planoConta = {}
        await this.$vs.notify(this.$notify.Success)
        this.modalCadastroPlanoConta = false
        this.errors.clear()
        await this.$emit('update')
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
        await this.$emit("update")
      }
    }
  },
  components: components
};
</script>

<style></style>
